import React from "react";
import Link from 'gatsby-link'
import Features from './Features';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"

export default props => (
  <div className="aside">
    <div className="aside--inner">
            {/* <Features /> */}
            <div className='social-link'>
                <h2>Suivez-nous</h2>
                <div className="social">
                        <ul className="">
                            <li>
                                <a href="https://www.facebook.com/AssociationJAIDE/"><FontAwesomeIcon size="2x" icon={faFacebook} /><span>Facebook</span></a>
                            </li>
                            <li>
                                <a href=""><FontAwesomeIcon size="2x" icon={faTwitter} /><span>Twitter</span></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/p/BoW2DNhHGyq/"><FontAwesomeIcon size="2x" icon={faInstagram} /><span>Instagram</span></a>
                            </li>
                        </ul>
                        </div>
            
            </div>
    </div>
  </div>
);

import React from 'react'
import Link from 'gatsby-link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"

const FooterBranding = () => (
  <div className="footer branding-footer">
    <div className="supfooter">
        <div className="supfooter__inner">
            <div className="split">
                <Link to="/faire-un-don">
                    <h2>Faire un don</h2>
                    <p>Prenez rendez-vous afin de pouvoir participer aux permanences sociales</p>
                </Link>
            </div>
            <div className="split">
                <Link to="/permanences-sociales">   
                    <h2>Permanences sociales</h2>
                    <p>Prenez rendez-vous afin de pouvoir participer aux permanences sociales </p>
                </Link>
            </div>
            <div className="split">
                <Link to="/devenir-benevole">
                    <h2>Devenir bénévole</h2>
                    <p>Welcome to a place where words matter. On Medium, smart voices and original ideas take center stage - with no ads in sight.</p>
                </Link>
            </div>
        </div>
    </div>
    <div className="content-wrapper">
        <div className="content-wrapper-up">
            <div className="split-2">
                <div className="left">
                    <div className="logo"><img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/Logo-white.png" alt="JAIDE"/></div>
                </div>    
                <div className="right">
                    <p>3 Chemin du Clos 95650 <br/> Puiseux-Pontoise</p>
                </div>    
            </div>
            <div className="split-2">
                {/* <Link to="/permanences-sociales">    */}
                    <ul className="social">
                        <li>
                            <a href="https://www.facebook.com/AssociationJAIDE/"><FontAwesomeIcon size="3x" icon={faFacebook} /></a>
                        </li>
                        {/* <li>
                            <a href="#"><FontAwesomeIcon size="3x" icon={faTwitter} /></a>
                        </li> */}
                        <li>
                            <a href="https://www.instagram.com/p/BoW2DNhHGyq/"><FontAwesomeIcon size="3x" icon={faInstagram} /></a>
                        </li>
                    </ul>
                {/* </Link> */}
            </div>
        </div>
        <div className="content-wrapper-down">
            <div className="left">
            <ul>
                <li>
                    <Link to="/a-propos">A propos</Link>
                </li>
                <li>
                    <Link to="/devenir-partenaire">Devenir partenaire</Link>
                </li>
                <li>
                    <Link to="/faire-un-don">Faire un don</Link>
                </li>
                <li>
                    <Link to="/mentions-legales">Mentions légales</Link>
                </li>
                <li>
                    <Link to="/traitement-des-donnees">Traitement de données</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
            </div>
            <div className="right">
            </div>
        </div>
        <div className="content-wrapper-down">
            <p>© JAIDE 2020 - Tous droits réservés</p>
        </div>

    </div>   
  
  </div>
)

export default FooterBranding
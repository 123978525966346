import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import FooterBranding from '../components/footer/FooterBranding'
import Aside from '../components/Aside'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"

const JAideMonProchain = props => (
  <Layout>

    <div className="container custom-container">
          <div className="content-wrapper title-page">
              <div className="content-wrapper-inner">
                    <h1>J’aide mon prochain</h1>
                    {/* <span>{props.location.hostname}{props.location.pathname}</span> */}
                </div>
          </div>
          <div className="container-inner">
          
            <div className="content-wrapper">
            <div className="content-wrapper-inner">
                <div className="content">
                  {/* <img src="/static/world-ff1f9ddb29df31c4df192075778a1d8c.jpg" alt=""/> */}
                  <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/06/rawpixel-661924-unsplash-1024x571-1.jpg" alt=""/>
                  <p>J’aide mon prochain comprend les actions à destination des « publics cibles » de l’association, notamment :</p>
                  <ul>
                    <li>les personnes en situation de précarité</li>
                    <li>les personnes en situation de précarité</li>
                    <li>les personnes en situation de handicap ou les personnes hospitalisées ;</li>
                    <li>les réfugiés ;</li>
                    <li>les personnes incarcérées ;</li>
                    <li>les prostitués ;</li>
                    <li>les jeunes en difficultés.</li>
                  </ul>
                </div>

                <aside>
                  <Aside/>
                </aside>
                
                
                
                
                
                
              </div>
            </div>
          </div>
      </div>
    <FooterBranding/>
  </Layout>
)

export default JAideMonProchain
